import React, { FC } from "react";
import { SEO } from "@wealthComponents/seo";
import {
  AuthPage,
  PromoTwo,
  PromoTwoBody,
} from "@ifgengineering/client-auth-components";

// name to be changed to Auth once we remove all legacy auth
const NewAuth: FC = () => {
  const authBgColor = "linear-gradient(180deg, #192a36, #000000)";

  return (
    <>
      <SEO title="Islamic Finance Guru" />
      <AuthPage
        Promo={() => <PromoTwo />}
        MobileFooter={() => <PromoTwoBody />}
        showHeader={true}
        showTrustBox={true}
        backgroundColor={authBgColor}
        authRedirect={"/app/calculate"}
      />
    </>
  );
};

export default NewAuth;
